// src/App.js
import React, { useState } from "react";
import "./App.css";

import lyonImage from "./assets/lyon.png";

// Sample data for top 10 croissants in Lyon
const croissantRankings = [
  {
    rank: 1,
    name: 'Boulangerie Pâtisserie "L\' Atelier Cours Albert Thomas"',
    address: "135 Cr Albert Thomas, 69003 Lyon",
    gmaplink: "https://maps.app.goo.gl/6wk7Nh6bc1zW7chv7",
    description:
      "Le croissant de L' Atelier Cours Albert Thomas peut sembler sec et décevant à première vue, mais il surprend par un bon goût de beurre et une texture aérée. Il offre une expérience classique de croissant légèrement supérieure à la moyenne.",
  },
  {
    rank: 2,
    name: "Maison Chadli",
    address: "29 Rue Masséna, 69006 Lyon",
    gmaplink:
      "https://www.google.fr/maps/place/Maison+chadli/@45.7702201,4.8494036,17z/data=!3m1!4b1!4m6!3m5!1s0x47f4ea8d02655555:0xf4997c2b052b36e0!8m2!3d45.7702202!4d4.8542745!16s%2Fg%2F11j8lmxg62?entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D",
    description:
      "Maison Chadli propose un croissant riche et généreux en beurre, offrant une expérience gourmande. Cependant, il perd sa texture aérée une fois tenu. L'aspect visuel pourrait être amélioré, mais le goût est délicieux.",
  },
  {
    rank: 3,
    name: "Boulangerie Pic et Mie",
    address: "41 Cr Albert Thomas, 69003 Lyon",
    gmaplink:
      "https://www.google.com/maps/place/Boulangerie+Pic+%26+Mie/@45.7480145,4.8621161,17z/data=!3m1!4b1!4m6!3m5!1s0x47f4ebf2c698297d:0xf5ea94140c0944b5!8m2!3d45.7480145!4d4.864691!16s%2Fg%2F11rvhmb0m1?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D",
    description:
      "À première vue, le croissant de la Boulangerie Pic et Mie peut ne pas sembler attrayant, mais il s'avère assez savoureux. Il n'est pas exceptionnel mais reste dans la moyenne, offrant une expérience surprenante et décente.",
  },
  {
    rank: 4,
    name: "ALMA par Marvin Brandao Pâtisserie-Boulangerie",
    address: "17 Rue Bugeaud, 69006 Lyon",
    gmaplink:
      "https://www.google.fr/maps/place/ALMA+par+Marvin+Brandao+P%C3%A2tisserie-Boulangerie/@45.7649525,4.8478387,15z/data=!4m10!1m2!2m1!1sAlma+boulangerie!3m6!1s0x47f4eb16967aa0af:0x53b2bd732196a666!8m2!3d45.7659747!4d4.8432782!15sChBBbG1hIGJvdWxhbmdlcmllWhIiEGFsbWEgYm91bGFuZ2VyaWWSAQpwYXRpc3Nlcmll4AEA!16s%2Fg%2F11jt0gcbz2?entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D",
    description:
      "Le croissant de ALMA par Marvin Brandao a un bel attrait visuel mais est assez sec et pas très généreux en beurre. L'expérience globale est quelque peu décevante malgré son apparence attrayante.",
  },
  {
    rank: 5,
    name: "Boulangerie Chez Jules Zola",
    address: "30 Cr Émile-Zola, 69100 Villeurbanne",
    gmaplink:
      "https://www.google.fr/maps/place/Boulangerie+Chez+Jules+Zola/@45.7649516,4.8478387,15z/data=!4m10!1m2!2m1!1sjules+patisserie!3m6!1s0x47f4ea851c700001:0x5092f03d162f811e!8m2!3d45.7704971!4d4.8652273!15sChBqdWxlcyBwYXRpc3NlcmllWhIiEGp1bGVzIHBhdGlzc2VyaWWSAQZiYWtlcnngAQA!16s%2Fg%2F11g6j7bxcf?entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D",
    description:
      "Le croissant de la Boulangerie Chez Jules Zola est également sec et légèrement moins agréable que celui de ALMA. L'attrait visuel est correct, mais l'expérience globale n'est pas très satisfaisante.",
  },
];

function App() {
  const [activeTab, setActiveTab] = useState("ranking");
  const [selectedBakery, setSelectedBakery] = useState(null);

  return (
    <div className="App">
      {/* Banner with title and croissant image */}
      <header className="App-header">
        {/* <h1>TITRE</h1> */}
        <h1>Le Meilleur Croissant</h1>
      </header>

      {/* Tabs */}
      <div className="tabs">
        <button onClick={() => setActiveTab("ranking")}>Lyon 2025</button>
        <button onClick={() => setActiveTab("about")}>A propos</button>
      </div>

      {/* Tab Content */}
      {activeTab === "ranking" && (
        <section className="ranking">
          <div className="flex-column">
            <div className="flex-row">
              <h2>Meilleurs Croissants à Lyon (2025)</h2>
              <img src={lyonImage} alt="Lyon" className="lyon-image" />
            </div>
          </div>

          <ul className="croissant-list">
            {croissantRankings.map((bakery) => (
              <React.Fragment key={bakery.rank}>
                <li className="croissant-item">
                  <div className="flex-row croissant-info">
                    <span className="rank">#{bakery.rank}</span>
                    <strong>{bakery.name}</strong>
                  </div>
                  {/* <span>{bakery.address}</span> */}
                  <a
                    href={bakery.gmaplink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="address"
                  >
                    {bakery.address}
                  </a>
                  <button onClick={() => setSelectedBakery(bakery)}>
                    +
                  </button>
                </li>
                <hr className="separator" /> {/* Separator between items */}
              </React.Fragment>
            ))}
          </ul>
        </section>
      )}

      {/* Dialog Box for Bakery Description */}
      {selectedBakery && (
        <div className="dialog">
          <div className="dialog-content">
            <h3>{selectedBakery.name}</h3>
            <p>{selectedBakery.description}</p>
            <button onClick={() => setSelectedBakery(null)}>Fermer</button>
          </div>
        </div>
      )}

      {activeTab === "about" && (
        <section className="description">
          <p>
            <b>
              Bienvenue sur votre guide ultime pour découvrir les meilleurs
              croissants de la capitale mondiale de la gastronomie !
            </b>
          </p>
          <p>
            Saviez-vous que c'est en 1935 que le célèbre critique culinaire
            Curnonsky a couronné Lyon du prestigieux titre de "capitale mondiale
            de la gastronomie" ? Depuis, cette ville emblématique continue de
            briller au firmament de la haute cuisine française, influencée par
            des figures légendaires comme Paul Bocuse et les mères lyonnaises.
            Mais ici, dans la ville où chaque repas est une fête, il n'y a pas
            que les bouchons qui méritent notre attention...
            <b> Les croissants aussi sont de véritables œuvres d'art !</b>
          </p>
          <p>
            Malheureusement, l'art du <b>vrai croissant</b> est en danger. En
            2008, on estimait que <b>40 % des croissants</b> vendus en France
            étaient fabriqués à partir de <b>pâte surgelée</b>. Pourtant, un
            croissant, aussi simple que cela puisse paraître, ne doit pas être
            banalisé ou artificiellement sublimé. Tout se retrouve dans la
            pureté des ingrédients : un bon beurre, une farine de qualité, et un
            savoir-faire artisanal qui ne peut être remplacé par des raccourcis
            industriels. Cet équilibre parfait est ce qui fait du croissant une
            expérience unique et précieuse.
          </p>
          <p>
            C'est pourquoi notre équipe d'experts sillonne les rues de Lyon,
            armée d'un palais affûté et d'une passion pour cette viennoiserie
            légendaire. Nous testons, comparons et classons les croissants des
            boulangeries aux quatre coins de la ville pour vous offrir une
            sélection des meilleurs. Chez nous, tout est pris en compte :
            l'aspect visuel (ce feuilletage doré irrésistible), le goût (bien
            sûr !), l'expérience globale (la première bouchée doit être
            magique), et surtout, la qualité du beurre - cet ingrédient clé qui
            fait toute la différence.
          </p>
          <p>
            Et parce que Lyon est une ville en constante évolution
            gastronomique, nos classements sont régulièrement mis à jour pour
            refléter les nouvelles pépites du moment. 🥐✨
          </p>
        </section>
      )}

      <div style={{ height: "30px" }}></div>
    </div>
  );
}

export default App;
